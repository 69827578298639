import React, { useCallback, useEffect, useState } from "react";
//import { useParams } from 'react-router';
import { Unity, useUnityContext } from "react-unity-webgl";


function Test() {
  const [data, setData] = useState('');

  /*
  const params = useParams();
  let { cont_seq, user_seq, level } = params;

  console.log('cont_seq', cont_seq);
  console.log('user_seq', user_seq);
  console.log('level', level);
  */

  const { 
    unityProvider, 
    isLoaded, 
    loadingProgression,
    sendMessage, // unity 함수를 호출하기 위한 sendMessage 추가
    addEventListener, // unity -> react 통신
    removeEventListener, // unity -> react 통신
  } = useUnityContext({
    loaderUrl: "https://cdn.cog-play.com/gamesData/WC_0/Build/Contents_0.loader.js",//"Build/as.loader.js",
    dataUrl: "https://cdn.cog-play.com/gamesData/WC_0/Build/Contents_0.data.unityweb",
    frameworkUrl: "https://cdn.cog-play.com/gamesData/WC_0/Build/Contents_0.framework.js.unityweb",
    codeUrl: "https://cdn.cog-play.com/gamesData/WC_0/Build/Contents_0.wasm.unityweb",
    streamingAssetsUrl: "StreamingAssets",
  });

  const loadingPercentage = Math.round(loadingProgression * 100);

  const set_Level = () => {
    sendMessage("Main", "Set_user_Index", 123);
    sendMessage("Main", "Set_contents_Index", 3);
    sendMessage("Main", "Set_Level", 3);
    sendMessage("Main", "Set_lang_Index", 0);
  }

  const get_gameResult = useCallback((userId, contents_Index, level, acc, score, answer_count, noanswer_count, play_Time, reaction) => {
    setData("유저 아이디 : " + userId + "콘텐츠 번호 : " + contents_Index + "레벨 : " + level + "정확도 : " + acc + "점수 : " + score + 
    "정답 수 : " + answer_count + "오답 수 : " + noanswer_count + "플레이 시간 : " + play_Time + "반응속도 : " + reaction);
  }, []);

  useEffect(() => {
    addEventListener("gameResult", get_gameResult);
    return () => {
      removeEventListener("gameResult", get_gameResult);
    };
  }, [addEventListener, removeEventListener, get_gameResult]);

  return (
    <>
    
      {isLoaded === false && (
        <div className="loading-overlay">
          <p>Loading... ({loadingPercentage}%)</p>
        </div>
      )}
        <Unity style={{ width: '90%', height: '100%', justifySelf: 'center', alignSelf: 'center' }} unityProvider={unityProvider} />
        <br />
        <button className='com_btn txt' onClick={set_Level}>
          Start
        </button>
        <p>{`${data}`}</p>
           
    </>
  )
}

export default Test;